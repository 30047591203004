<template lang="">
  <span>
    <div class="p-fluid formgrid grid">
      <div class="field col-6 md:col-6">
        <label for="dataCadastroInicio">Data Cadastro Inicial</label>
        <Calendar
          id="dataCadastroInicio"
          v-model="v$.dataCadastroInicio.$model"
          :class="{
            'p-invalid': submitted && v$.dataCadastroInicio.$invalid,
          }"
          dateFormat="dd/mm/yy"
          locale="pt-BR"
          :touchUI="true"
          @input="adicionarDataCadastroInicio" />
        <div
          v-if="submitted && v$.dataCadastroInicio.required.$invalid"
          class="p-error">
          O campo data cadastro início é obrigatório.
        </div>
      </div>
      <div class="field col-6 md:col-6">
        <label for="dataCadastroFim">Data Cadastro Final</label>
        <Calendar
          id="dataCadastroFim"
          v-model="v$.dataCadastroFim.$model"
          :class="{
            'p-invalid': submitted && v$.dataCadastroFim.$invalid,
          }"
          dateFormat="dd/mm/yy"
          locale="pt-BR"
          :touchUI="true"
          @input="adicionarDataCadastroFim" />
        <div
          v-if="submitted && v$.dataCadastroFim.required.$invalid"
          class="p-error">
          O campo data cadastro fim é obrigatório.
        </div>
      </div>
      <div class="field col-6 md:col-6">
        <label for="cpfServidor">CPF do Servidor</label>
        <InputMask
          v-model="v$.cpf.$model"
          mask="999.999.999-99"
          :class="{
            'p-invalid': submitted && v$.cpf.$invalid,
          }"
          placeholder="999.999.999-99"
          :disabled="usuarioTemApenasPermissaoDeServidor()"
          @input="adicionarCpf" />
        <div v-if="submitted && v$.cpf.required.$invalid" class="p-error">
          O campo cpf é obrigatório.
        </div>
      </div>
      <div class="field col-6 md:col-6">
        <label for="status">Situação</label>
        <MultiSelect
          id="status"
          v-model="v$.statusSelecionados.$model"
          dataKey="id"
          :class="{
            'p-invalid': submitted && v$.statusSelecionados.$invalid,
          }"
          :options="listaStatus"
          optionLabel="nome"
          filterPlaceholder="Procure pelo nome da situação"
          :emptyFilterMessage="'Nenhuma situação encontrada'"
          placeholder="Selecione uma situação"
          display="chip"
          :filter="true"
          @input="adicionarStatus">
        </MultiSelect>
        <div
          v-if="submitted && v$.statusSelecionados.required.$invalid"
          class="p-error">
          O campo situação é obrigatório.
        </div>
      </div>
    </div>
  </span>
</template>
<script>
import UseVuelidate from '@vuelidate/core'
import { watch } from 'vue-demi'
import { required } from '@vuelidate/validators'
import StatusConsignacaoService from '@/service/StatusConsignacaoService'
import { consignatariaStore } from '@/stores/consignataria'
import ServidorService from '@/service/ServidorService'

export default {
  name: 'LayoutFilter03',

  props: {
    submitted: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const store = consignatariaStore()
    return { v$: UseVuelidate(), store }
  },

  data() {
    return {
      consigntariaId: null,
      cpf: null,
      listaStatus: [],
      statusSelecionados: [],
      statusIdSelecionados: [],
      dataCadastroInicio: '',
      dataCadastroFim: '',
      parametros: new Map(),
    }
  },

  created() {
    this.statusConsignacaoService = new StatusConsignacaoService(this.$http)
    this.servidorService = new ServidorService(this.$http)
    watch(
      () => this.store.consignatariaSelecionada,
      () => {
        this.adicionarConsignataria()
      },
      { deep: true },
    )
  },

  mounted() {
    this.carregarListaStatus()
    this.carregarCpf()
    this.adicionarConsignataria()
  },

  validations() {
    return {
      cpf: { required },
      dataCadastroInicio: { required },
      dataCadastroFim: { required },
      statusSelecionados: { required },
    }
  },

  methods: {
    validate() {
      this.v$.dataCadastroInicio.$touch()
      this.v$.dataCadastroFim.$touch()
      this.v$.statusSelecionados.$touch()

      return this.v$.dataCadastroInicio.$invalid ||
        this.v$.dataCadastroFim.$invalid ||
        this.v$.statusSelecionados.$invalid
        ? true
        : false
    },

    limpar() {
      if (!this.usuarioTemApenasPermissaoDeServidor) {
        this.cpf = null
      }
      this.dataCadastroInicio = ''
      this.dataCadastroFim = ''
      this.statusSelecionados = ''
    },

    carregarListaStatus() {
      this.statusConsignacaoService.getListaStatus().then((res) => {
        this.listaStatus = res
      })
    },

    carregarCpf() {
      this.servidorService.getDadosServidor().then((res) => {
        if (this.usuarioTemApenasPermissaoDeServidor()) {
          this.cpf = res.cpf
          this.adicionarCpf()
        }
      })
    },

    adicionarDataCadastroInicio() {
      this.parametros.set(
        'dataCadastroInicio',
        this.dataCadastroInicio.toLocaleDateString('pt-BR'),
      )
      this.$emit('parametros', this.parametros)
    },

    adicionarDataCadastroFim() {
      this.parametros.set(
        'dataCadastroFim',
        this.dataCadastroFim.toLocaleDateString('pt-BR'),
      )
      this.$emit('parametros', this.parametros)
    },

    adicionarStatus() {
      this.adicionarListaIds('status')
      this.parametros.set('statusId', this.statusIdSelecionados)
      this.$emit('parametros', this.parametros)
      this.statusIdSelecionados = []
    },

    adicionarListaIds(tipo) {
      if (tipo == 'status') {
        this.statusSelecionados.forEach((valor) =>
          this.statusIdSelecionados.push(valor.id.toString()),
        )
      }
    },

    adicionarCpf() {
      this.parametros.set('cpf', this.retirarMascaraCpf(this.cpf))
      this.$emit('parametros', this.parametros)
    },

    adicionarConsignataria() {
      if (
        this.store.consignatariaSelecionada.id &&
        !this.parametros.get('consignatariaId')
      ) {
        this.parametros.set(
          'consignatariaId',
          this.store.consignatariaSelecionada.id,
        )
        this.$emit('parametros', this.parametros)
      }
    },

    retirarMascaraCpf(ObjCPF) {
      return ObjCPF.replace(/\D/g, '')
    },

    usuarioTemPermissaoDeGestao() {
      return (
        this.$auth.hasRoleGestao() ||
        this.$auth.hasRoleGestaoAdmin() ||
        this.$auth.hasRoleGestaoEntidade() ||
        this.$auth.hasRoleGestaoFinanceira()
      )
    },

    usuarioTemPermissaoDeConsignataria() {
      return (
        this.$auth.hasRoleConsignatariaUser() ||
        this.$auth.hasRoleConsignatariaAdmin()
      )
    },

    usuarioTemPermissaoDeServidor() {
      return this.$auth.hasRoleServidor()
    },

    usuarioTemApenasPermissaoDeServidor() {
      return (
        !this.usuarioTemPermissaoDeGestao() &&
        !this.usuarioTemPermissaoDeConsignataria() &&
        this.usuarioTemPermissaoDeServidor()
      )
    },
  },
}
</script>
<style lang=""></style>
