var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{attrs:{"for":"dataCadastroInicio"}},[_vm._v("Data Cadastro Inicial")]),_c('Calendar',{class:{
          'p-invalid': _vm.submitted && _vm.v$.dataCadastroInicio.$invalid,
        },attrs:{"id":"dataCadastroInicio","dateFormat":"dd/mm/yy","locale":"pt-BR","touchUI":true},on:{"input":_vm.adicionarDataCadastroInicio},model:{value:(_vm.v$.dataCadastroInicio.$model),callback:function ($$v) {_vm.$set(_vm.v$.dataCadastroInicio, "$model", $$v)},expression:"v$.dataCadastroInicio.$model"}}),(_vm.submitted && _vm.v$.dataCadastroInicio.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo data cadastro início é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{attrs:{"for":"dataCadastroFim"}},[_vm._v("Data Cadastro Final")]),_c('Calendar',{class:{
          'p-invalid': _vm.submitted && _vm.v$.dataCadastroFim.$invalid,
        },attrs:{"id":"dataCadastroFim","dateFormat":"dd/mm/yy","locale":"pt-BR","touchUI":true},on:{"input":_vm.adicionarDataCadastroFim},model:{value:(_vm.v$.dataCadastroFim.$model),callback:function ($$v) {_vm.$set(_vm.v$.dataCadastroFim, "$model", $$v)},expression:"v$.dataCadastroFim.$model"}}),(_vm.submitted && _vm.v$.dataCadastroFim.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo data cadastro fim é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{attrs:{"for":"cpfServidor"}},[_vm._v("CPF do Servidor")]),_c('InputMask',{class:{
          'p-invalid': _vm.submitted && _vm.v$.cpf.$invalid,
        },attrs:{"mask":"999.999.999-99","placeholder":"999.999.999-99","disabled":_vm.usuarioTemApenasPermissaoDeServidor()},on:{"input":_vm.adicionarCpf},model:{value:(_vm.v$.cpf.$model),callback:function ($$v) {_vm.$set(_vm.v$.cpf, "$model", $$v)},expression:"v$.cpf.$model"}}),(_vm.submitted && _vm.v$.cpf.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo cpf é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{attrs:{"for":"status"}},[_vm._v("Situação")]),_c('MultiSelect',{class:{
          'p-invalid': _vm.submitted && _vm.v$.statusSelecionados.$invalid,
        },attrs:{"id":"status","dataKey":"id","options":_vm.listaStatus,"optionLabel":"nome","filterPlaceholder":"Procure pelo nome da situação","emptyFilterMessage":'Nenhuma situação encontrada',"placeholder":"Selecione uma situação","display":"chip","filter":true},on:{"input":_vm.adicionarStatus},model:{value:(_vm.v$.statusSelecionados.$model),callback:function ($$v) {_vm.$set(_vm.v$.statusSelecionados, "$model", $$v)},expression:"v$.statusSelecionados.$model"}}),(_vm.submitted && _vm.v$.statusSelecionados.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo situação é obrigatório. ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }